#root,
html,
body {
  width: 100%;
  height: 100%;
  min-width: 25rem;
}

* {
  padding: 0;
  margin: 0;
  outline: 0;
}
